<script>
import { mapState, mapActions } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
import SocialNetworks from './SocialNetworks.vue'

export default {
  name: 'MenuMobile',
  components: { SocialNetworks },
  emits: ['routeClicked'],

  data() {
    return {
      clientWidth: window.innerWidth,
      showMainMenu: true,
      permalinksReady: false
    }
  },

  computed: {
    ...mapState(useMultilingualStore, ['menuMobile', 'lang', 'options', 'translatedPermalinks']),
    menuItems() {
      return this.menuMobile.menu.map(item => {
        const translatedPermalink = this.getTranslatedPermalink(item.slug)
        //console.log(`Original slug: ${item.slug}, Translated permalink: ${translatedPermalink}`)
        return {
          ...item,
          translatedPermalink
        }
      })
    }
  },

  watch: {
    clientWidth(val) {
      if (this.lang === 'ja') {
        this.showMainMenu = val > 1330
      }
      else {
        this.showMainMenu = val > 1200
      }
    },
  },

  methods: {
    ...mapActions(useMultilingualStore, ['getTranslatedPermalink', 'getTranslatedPermalinks']),
    async loadPermalinks() {
      await this.getTranslatedPermalinks()
      //console.log('Permalinks loaded for mobile menu:', this.translatedPermalinks)
      this.permalinksReady = true
    }
  },

  created() {
    window.addEventListener('resize', () => {
      this.clientWidth = window.innerWidth
    })
    this.loadPermalinks()
  },
}
</script>

<template>
  <nav v-show="showMainMenu" id="menu" style="margin-right: 0px; margin-left: -400px; opacity: 1; top: 100px; height: 943px;">
    <div id="menu_content">
      <div class="content_width_wrapper_menu">
        <ul id="menu-menu-mobile" class="menu">
          <li v-for="item in menuItems" :key="item.slug" class="menu-item menu-item-type-post_type">
            <router-link
              :to="item.translatedPermalink"
              @click="$emit('routeClicked')"
            >
              {{ item.title }}
            </router-link>
          </li>
        </ul>
        <p id="burger_rs_accroche">
          {{ options.accroche_menu_mobile_suiveznous }}
        </p>
        <SocialNetworks />
      </div>
    </div>
  </nav>
</template>

<style>

</style>
