export function resizeColonneDoubleLarge() {
  let contentWidth = document.getElementsByClassName('content_width')

  contentWidth = contentWidth[1].offsetWidth

  const colToResize = document.querySelectorAll(
    '.col_right_wide_imgleft_wrapper, .col_left_wide_imgright_wrapper, .col_left_wide_imgleft_img_texte, .col_right_wide_imgright_img_texte',
  )

  colToResize.forEach((col) => {
    col.style.width = `${contentWidth * 0.5}` + 'px'
  })
}
