import { defineStore } from 'pinia'
import { getApiData } from '../utils/getApi'
import { transformDate } from '../utils/transformDate'
import { useRouter } from '../router/router'

export const useMultilingualStore = defineStore('MultilingualStore', {
  state: () => {
    return {
      captchaAnswer: '',
      contactFormFields: [],
      contactFormData: {},
      courses: [],
      currentWebsite: '',
      esgTalks: [],
      home: '',
      id: 0,
      lang: '',
      languages: [],
      menu: {
        menu: [],
        lang: '',
      },
      menuFooter: {
        menu: [],
        lang: '',
      },
      menuMobile: {
        menu: [],
        lang: '',
      },
      nextPost: {},
      noContent: false,
      options: {},
      optionsPage: {},
      prevPost: {},
      page: {},
      pageSlug: '',
      posts: [],
      searchResults: [],
      searchBoxOpened: false,
      searchQuery: '',
      subpageSlug: '',
      title: '',
      translations: {},
      uri: '',
      uris: {},
      apiCallsByMistake: 0,
      // website: 'candriam.dev-nanosite.tech',
      // website: 'candriam.nanosite.tech',
      // website: 'candriam',
      website: 'api-academy.candriam.com',
      // host: 'candriam-app.nanosite.tech',
      protocol: 'https://',
      metaTitle: '',
      metalLocale: '',
      seo: {},
      translatedPermalinks: {},
      frontendUrl: 'https://academy.candriam.com', // http://localhost:8080/fr
      backendUrl: 'https://api-academy.candriam.com',
      newsSlugs: {
        en: 'news',
        fr: 'actualites',
        de: 'nachrichten',
        es: 'noticias',
        it: 'notizie',
        nl: 'nieuws'
      },
      contactSlugs: {
        fr: 'contact',
        en: 'contact',
        nl: 'neem-contact-met-ons-op',
        de: 'kontakt',
        es: 'contacto',
        it: 'contatto'
      },
    }
  },

  actions: {
    incrementApiCall() {
      this.apiCallsByMistake++
    },
    async getData(lang, data) {
      //   console.log('Get data est appelée, data : ', data, ' lang : ', lang)
      this.lang = lang
      this.currentWebsite = `${window.location.origin}/${this.lang}`
      // console.log(data)
      this.subpageSlug = data.subpage
      data.page = this.handleHomeRoute(data.page) // dans le  cas où l'utilisateur va sur la page d'accueil sans spécification de la langue ou d'une page précise

      if (data.subpage) {
        // console.log('SOUS PAGE')
        this.handleSubpage(data)
      }
      else {
        // console.log('PAGE')
        this.handlePage(data)
      }
    },
    /**
     * Modifie l'ordre dans le tableau des langues disponibles à la traduction, puisque le composant en front se base sur ce tableau
     */
    changeOrder() {
      // console.log('Changement d\'ordre des langues dans le dropdown')
      if (this.languages.find(language => language === this.lang)) {
        this.languages = this.languages.filter(language => language !== this.lang)
        this.languages.unshift(this.lang)
      }
      else { // si la langue de navigateur n'est pas dans le tableau des langues disponibles, on remplace la langue de l'appli par la langue anglaise
        this.lang = 'en'
      }
    },
    changePageSlug(slug) {
      this.pageSlug = slug
    },
    emptyContactFormFields() {
      this.contactFormFields = []
    },
    fillCaptcha(answer) {
      this.captchaAnswer = ''
      this.captchaAnswer = answer
    },
    fillContactFormData(data) {
      this.contactFormData = {
        ...this.contactFormData,
        ...data,
      }
    },
    /**
     * Gère le cas où l'utilisateur retourne à la page d'accueil ou bien enlève tous les paramètres de la route
     * @param {object} dataPage
     * @returns
     */
    handleHomeRoute(dataPage) {
      if (dataPage === '' || dataPage === undefined) {
        dataPage = 'home'
      }
      return dataPage
    },
    getACFDataFromPage(id) {
      getApiData(
        `${this.protocol + this.website}/wp-json/acf/v3/pages/${id}`,
      ).then((response) => {
        this.optionsPage = response.acf
      })
    },
    getContactFormFields(id) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      }
      if (this.contactFormFields.length === 0) {
        fetch(`${this.protocol + this.website}/wp-json/contact-form-7/v1/contact-forms/${id}/feedback/schema`, requestOptions)
          .then(response => response.json())
          .then((result) => {
            const groupedFields = {};
        
            result.rules.forEach((item) => {
              if (!groupedFields[item.field]) {
                groupedFields[item.field] = {
                  name: item.field,
                  label: item.field,
                  required: item.rule === 'required'
                };
              }
              // Si une règle 'required' est trouvée, marquer le champ comme requis
              if (item.rule === 'required') {
                groupedFields[item.field].required = true;
              }
            });
        
            this.contactFormFields = Object.values(groupedFields);
            console.log('Grouped contactFormFields:', this.contactFormFields);
          })
          .catch(error => console.error('error', error));
      }
    },
    getCourses() {
      /**
       * Explication de la condition principale de cette fonction : S'il n'y a pas de cours dans le store, on appelle les cours en fonction de la langue
       * S'il y a déjà des cours dans le store, on vérifie leur langue, et s'ils sont différents de la page en cours, on rappelle des cours avecla bonne langue
       */
      if (this.pageSlug === 'courses') {
        // console.log('les cours sont appelés')
        if (this.courses.length === 0) {
          getApiData(`${this.protocol + this.website}/wp-json/wp/v2/course/?lang=${this.lang}`).then((courses) => {
            courses.forEach((course) => {
              this.courses.push({
                title: {
                  rendered: course.title.rendered,
                },
                content: {
                  rendered: course.content.rendered,
                },
                id: course.id,
                slug: course.slug,
                description: course.acf.description_extrait_de_la_page,
                lang: course.lang,
              })
            })
          })
        }
        else { // il y a déjà des cours dans le store, on va donc vérifier s'il y a un changement de langue
          if (this.lang !== this.courses[0].lang) {
            getApiData(`${this.protocol + this.website}/wp-json/wp/v2/course/?lang=${this.lang}`).then((courses) => {
              this.courses = []
              courses.forEach((course) => {
                this.courses.push({
                  title: {
                    rendered: course.title.rendered,
                  },
                  content: course.content.rendered,
                  id: course.id,
                  slug: course.slug,
                  description: course.acf.description_extrait_de_la_page,
                  lang: course.lang,
                })
              })
            })
          }
        }
      }
    },
    getESGTalks() {
      // console.log('Dans la fonction ESG Talk')
      if (this.pageSlug === 'esg-talks' && (this.esgTalks.length === 0)) {
        // console.log('Esg Talks Appelés')
        getApiData(
          `${this.protocol + this.website}/wp-json/wp/v2/esg-talk?per_page=100`,
        ).then((esgTalks) => {
          this.esgTalks = []
          esgTalks.forEach((esgTalk) => {
            this.esgTalks.push({
              title: {
                rendered: esgTalk.title.rendered,
              },
              date: transformDate(esgTalk.date, this.lang),
              numero: esgTalk.acf.numero_de_lesg_talk,
              content: esgTalk.content.rendered,
              slug: esgTalk.slug,
              id: esgTalk.id,
            })
          })
        })
      }
    },
    getLanguagesFromApi() {
      // console.log('Récupération des langues au lancement de la route')
      return getApiData(
        `${this.protocol + this.website}/wp-json/pll/v1/languages`,
      )
    },
    getMenus() {
      // console.log('les menus sont appelés')
      if (this.lang === '') {
        this.lang = 'en'
      }
      if (this.menu.menu.length === 0 || this.menu.lang !== this.lang) {
        getApiData(
          `${this.protocol + this.website}/wp-json/menus/v1/menus/menu-principal-${this.lang}`,
        ).then((menu) => {
          this.menu.menu = menu.items
          this.menu.lang = this.lang
        })
      }

      if (this.menuFooter.menu.length === 0 || this.menuFooter.lang !== this.lang) {
        getApiData(
          `${this.protocol + this.website}/wp-json/menus/v1/menus/menu-footer-${this.lang}`,
        ).then((menu) => {
          this.menuFooter.menu = menu.items
          this.menuFooter.lang = this.lang
        })
      }

      if (this.menuMobile.menu.length === 0 || this.menuMobile.lang !== this.lang) {
        getApiData(
          `${this.protocol + this.website}/wp-json/menus/v1/menus/menu-mobile-${this.lang}`,
        ).then((menu) => {
          this.menuMobile.menu = menu.items
          this.menuMobile.lang = this.lang
        })
      }
    },
    getOptions() {
      getApiData(
        `${this.protocol + this.website}/wp-json/acf/v3/options/options?lang=${this.lang}`,
      ).then((options) => {
        this.options = options.acf
      })
    },
    getOptionsForSearch() {
      return getApiData(
        `${this.protocol + this.website}/wp-json/acf/v3/options/options?lang=${this.lang}`,
      )
    },
    getPosts() {
      getApiData(
        `${this.protocol + this.website}/wp-json/wp/v2/posts?lang=${this.lang}`,
      ).then((posts) => {
        // console.log('POSTS RECUPERES')
        this.posts = []
        posts.forEach((post) => {
          post.date = transformDate(post.date, this.lang)
          this.posts.push(post)
        })
      })
    },
    getPublicPermalink(id) {
      getApiData(
        `${this.protocol + this.website}/wp-json/permalinkmanager/v1/uri/${id}`,
      ).then((response) => {
        this.uri = response
      })
    },
    /***
     * Traduit la page et revoie "true" ou renvoie false, pour qu'une nouvelle requête soit lancée
     */
    async getPageTranslation(lang) {
      const translationId = this.translations[lang]
      if (translationId) {
        const firstResponse = await getApiData(`${this.protocol + this.website}/wp-json/wp/v2/pages/${translationId}`)
        const pageContent = await getApiData(`${this.protocol + this.website}/wp-json/wp/v2/pages/?slug=${firstResponse.slug}&lang=${this.lang}`)
        this.updateStoreAndPageContent(pageContent[0]) // le renvoi des pages est un tableau, il faut donc mettre [0], contrairement aux posts
        return true
      }
      return false
    },

    /**
      * Gère une page de manière asynchrone en récupérant son contenu à partir de l'API et en mettant à jour le contenu du magasin et de la page.
     *
     * @param {Object} data - an object containing the page data
     */
    async handlePage(data) {
      if (data.page !== 'search') {
        const getContentFromSlugAndLang = (slug) => {
          //   console.log(`${this.protocol + this.website}/wp-json/wp/v2/pages/?slug=${slug}&lang=${this.lang}`)
          return getApiData(`${this.protocol + this.website}/wp-json/wp/v2/pages/?slug=${slug}&lang=${this.lang}`)
        }
        if (Object.keys(this.uris).length === 0) {
          this.uris = await getApiData(`${this.protocol + this.website}/wp-json/permalinkmanager/v1/uris/`)
        }

        // si un ID est trouvé, cela veut dire que l'utilisateur a entrée l'URI dans le navigateur, or la requête API ne fonctionne bien qu'avec le slug
        const idFound = Object.keys(this.uris).find(key => this.uris[key] === data.page)
        let pageContent

        if (idFound) {
          const pageData = await getApiData(`${this.protocol + this.website}/wp-json/wp/v2/pages/${idFound}`)
          pageContent = await getContentFromSlugAndLang(pageData.slug)
        }
        else {
          pageContent = await getContentFromSlugAndLang(data.page)
        }
        if (pageContent.length === 0) {
          this.updateStoreAndPageContentFor404()
        }
        else {
          this.updateStoreAndPageContent(pageContent[0])
        }
      }

      else if (data.page === 'search') {
        this.search(this.searchQuery, this.lang)
        this.getOptionsForSearch().then((options) => {
          this.options = options.acf
          this.updateStoreAndPageContentForSearch()
        })
      }
    },
    handleSubpage(data) {
      switch (data.page) {
        case 'esg-talks':
          // console.log('ESG TALKS')

          getApiData(
            `${this.protocol + this.website}/wp-json/wp/v2/esg-talk`,
          ).then((esgTalks) => {
            this.esgTalks = []
            esgTalks.forEach((esgTalk) => {
              this.esgTalks.push({
                title: {
                  rendered: esgTalk.title.rendered,
                },
                date: transformDate(esgTalk.date, this.lang),
                numero: esgTalk.acf.numero_de_lesg_talk,
                content: esgTalk.content.rendered,
                slug: esgTalk.slug,
                id: esgTalk.id,
                translations: esgTalk.translations,
              })
            })
            this.esgTalks.forEach((esgTalk) => {
              if (data.subpage === esgTalk.slug) {
                this.getMenus()
                this.getPosts()
                this.getESGTalks()
                this.pageSlug = 'esg-talks'
                this.page = esgTalk.content
                this.id = esgTalk.id
                this.title = esgTalk.title.rendered
                this.translations = []
              }
            })
          })
          break
        case 'news':
          // console.log('Dans une news : ', data.subpage)
          this.getMenus()
          this.pageSlug = 'news'
          getApiData(
            `${this.protocol + this.website}/wp-json/wp/v2/posts?lang=${this.lang}`,
          ).then((posts) => {
            // console.log(posts)
            this.posts = []
            posts.forEach((post) => {
              post.date = transformDate(post.date, this.lang)
              this.posts.push(post)
            })

            // On modifie le contenu de la page
            this.posts.forEach((post) => {
              if (data.subpage === post.slug) {
                this.updateStoreAndPageContent(post)
              }
            })
          })
          break
        case 'courses':
          this.pageSlug = 'courses'
          // console.log(`HandleSubpage : ${this.pageSlug} :`, `${this.protocol + this.website}/wp-json/wp/v2/course/?lang=${this.lang}`)
          getApiData(`${this.protocol + this.website}/wp-json/wp/v2/course/?lang=${this.lang}`).then((courses) => {
            this.courses = []
            courses.forEach((course) => {
              this.courses.push({
                title: {
                  rendered: course.title.rendered,
                },
                content: {
                  rendered: course.content.rendered,
                },
                id: course.id,
                slug: course.slug,
                description: course.acf.description_extrait_de_la_page,
                lang: course.lang,
                translations: course.translations,
              })
            })

            // C'est ici qu'on modifie le contenu de la page vue par l'utilisateur
            this.courses.forEach((course) => {
              if (data.subpage === course.slug) {
                this.updateStoreAndPageContent(course)
              }
            })
          })
          break
        default:
          this.getSubpageWithId(data)
          break
      }
    },
    async getSubpageWithId(data) {
      const uri = `${data.page}/${data.subpage}`
      this.uris = await getApiData(`${this.protocol + this.website}/wp-json/permalinkmanager/v1/uris/`)
      const subpageId = Object.keys(this.uris).find(key => this.uris[key] === uri)
      if (subpageId) {
        try {
          const response = await getApiData(`${this.protocol + this.website}/wp-json/wp/v2/posts/${subpageId}`)
          this.updateStoreAndPageContent(response)
        }
        catch (error) {
          console.error(error)
        }
      }
    },
    removeScriptTagsFromCookies() {
      this.options.google_analytics = this.options.google_analytics.replace(/<script>|<\/script>|<!-- Google Tag Manager -->|<!-- End Google Tag Manager -->/g, '')
    },
    /**
     * Fonction de recherche utilisant un endpoint custom dans functions.php. La fonction du endpoint s'appelle "custom_search_function"
     * @param {string} string // Prend une string ou plusieurs string, fonctionne sur le mode "AND" et non "OR"
     *  */
    search(string, lang) {
      this.pageSlug = 'search'
      // console.log('Lancement de la recherche, searchQuery :', string, ' ; langue : ', lang)
      this.page = []
      // console.log(string)
      this.searchResults = []
      const apiSearchRoute = `${this.protocol + this.website}/wp-json/v1/search/?s=${string}&lang=${lang}`
      getApiData(apiSearchRoute).then((results) => {
        this.searchResults = results
      })
    },
    /**
     * Modifie la langue dans le store basé sur le clique de l'utilisateur
     * @param {string} lang Langue sélectionnée sur le site
     */
    setLang(lang) {
      this.lang = lang
      // console.log(`La langue "${this.lang}" settée grâce à selectLang, rappelle getData dans le store`)
    },
    getCourseTranslation() {
      this.courses.forEach((course) => {
        const translationId = course.translations[`${this.lang}`]
        if (translationId) {
          // console.log('On appelle la traduction du cours qui est dispo')
          getApiData(`${this.protocol + this.website}/wp-json/wp/v2/course/${translationId}`).then((response) => {
            this.updateStoreAndPageContent(response)
          })
        }
        else {
          // console.log('Pas de traduction trouvée')
        }
      })
    },
    updateTitle(value) {
      this.title = value
    },

    updateSearchBoxState() {
      this.searchBoxOpened = !this.searchBoxOpened
    },

    recordSearchQuery(value) {
      this.searchQuery = value
    },
    updateStoreAndPageContent(value) {
      this.seo = value.yoast_head_json
      this.page = value.content.rendered
      this.translations = []
      this.translations = value.translations
      this.id = value.id
      this.title = value.title.rendered
      this.getMenus()
      this.getACFDataFromPage(value.id)
      this.getPublicPermalink(value.id)
      this.getPosts()
      this.getCourses()
      this.getESGTalks()
      this.changeOrder()
    },
    updateStoreAndPageContentForSearch() {
      this.pageSlug = 'search'
      this.title = this.options.fil_ariane_resultats_recherche
      this.getMenus()
      this.getPosts()
      this.getCourses()
      this.getESGTalks()
      this.changeOrder()
    },
    updateStoreAndPageContentFor404() {
      this.pageSlug = '404'
      this.title = '404'
      this.seo = {
        og_title: this.options.page_404_titre,
        title: this.options.page_404_titre,
        og_local: this.lang,
        og_type: 'Page',
      }
      this.getMenus()
      this.getPosts()
      this.getCourses()
      this.getESGTalks()
      this.changeOrder()
      const router = useRouter() // Get the router instance
      this.currentWebsite = `${window.location.origin}/${this.lang}`
      router.push({
        name: 'Principale',
        params: {
          lang: this.lang, page: '404',
        },
      })
    },
    updatePrevAndNext(page) {
      const posts = (page === 'esg-talks') ? this.esgTalks : (page === 'courses') ? this.courses : this.posts
      const currentIndex = posts.findIndex(post => post.id === this.id)
      const prevPostIndex = currentIndex - 1
      const nextPostIndex = currentIndex + 1

      this.prevPost = (posts[prevPostIndex])
        ? {
            exist: true,
            slug: posts[prevPostIndex].slug,
            name: posts[prevPostIndex].title.rendered,
          }
        : { exist: false }

      this.nextPost = (posts[nextPostIndex])
        ? {
            exist: true,
            slug: posts[nextPostIndex].slug,
            name: posts[nextPostIndex].title.rendered,
          }
        : { exist: false }
    },

    async getTranslatedPermalinks() {
      if (Object.keys(this.translatedPermalinks).length === 0) {
        //console.log('Fetching translated permalinks from posts...')
        const response = await getApiData(`${this.backendUrl}/wp-json/wp/v2/pages?per_page=100`)
        //console.log('API response:', response)
        
        response.forEach(post => {
          if (!this.translatedPermalinks[post.slug]) {
            this.translatedPermalinks[post.slug] = {}
          }
          this.translatedPermalinks[post.slug][post.lang] = post.link
          
          // Gestion spécifique pour les slugs de news et contact
          if (Object.values(this.newsSlugs).includes(post.slug) || Object.values(this.contactSlugs).includes(post.slug)) {
            const slugs = Object.values(this.newsSlugs).includes(post.slug) ? this.newsSlugs : this.contactSlugs
            Object.entries(slugs).forEach(([lang, pageSlug]) => {
              if (!this.translatedPermalinks[pageSlug]) {
                this.translatedPermalinks[pageSlug] = {}
              }
              this.translatedPermalinks[pageSlug][lang] = post.link
            })
          }
        })
        
        //console.log('Translated permalinks:', this.translatedPermalinks)
      }
    },

    getTranslatedPermalink(slug) {
      //console.log(`Getting permalink for: ${slug}, Current language: ${this.lang}`)
      let permalink = this.translatedPermalinks[slug]?.[this.lang]
      
      // Gestion spéciale pour les slugs de news et contact
      if (!permalink) {
        if (Object.values(this.newsSlugs).includes(slug)) {
          const newsSlug = this.newsSlugs[this.lang]
          permalink = this.translatedPermalinks[newsSlug]?.[this.lang]
        } else if (Object.values(this.contactSlugs).includes(slug)) {
          const contactSlug = this.contactSlugs[this.lang]
          permalink = this.translatedPermalinks[contactSlug]?.[this.lang]
        }
      }
      
      if (permalink) {
        const url = new URL(permalink)
        let path = url.pathname
        
        // Supprimez tous les préfixes de langue
        Object.keys(this.newsSlugs).forEach(lang => {
          const langPrefix = `/${lang}/`
          while (path.startsWith(langPrefix)) {
            path = path.slice(langPrefix.length)
          }
        })
        
        // Assurez-vous que le chemin ne commence pas déjà par la langue actuelle
        const currentLangPrefix = `/${this.lang}/`
        if (!path.startsWith(currentLangPrefix)) {
          path = currentLangPrefix + path
        }
        
        const frontendPath = path.replace(/\/+/g, '/')
        //console.log(`Permalink found: ${frontendPath}`)
        return frontendPath
      }
      //console.log(`No permalink found, using default slug: ${slug}`)
      return `/${this.lang}/${slug}`
    },

    
  },
})
