export function ajoutDeClasses() {
  // sideBar Actualité
  const nombreArticles = 3
  const articleExtrait = document.querySelectorAll(
    '#aside_actualites_wrapper .article_extrait',
  )
  for (let i = 0; i < nombreArticles; i++) {
    if (articleExtrait[i] !== undefined) {
      articleExtrait[i].classList.add(`article_extrait_${i + 1}`)
    }
  }
}
