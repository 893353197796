<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'

export default {
  name: 'HeaderHome',
  computed: {
    ...mapState(useMultilingualStore, ['optionsPage']),
  },
  updated() {
    if (this.optionsPage.accroche_dentete) {
      this.modifyLastCharacterOfTitle()
    }
  },
  methods: {
    /**
     * Le titre du header est modifié pour que le point final passe en bleu
     */
    modifyLastCharacterOfTitle() {
      const title = this.$refs.h1Title
      const textContent = title.textContent
      const lastCharIndex = textContent.length - 1

      if (textContent.charAt(lastCharIndex) === '.') {
        const modifiedText = `${textContent.substring(0, lastCharIndex)}<span style="color:#0071BA">.</span>`
        title.innerHTML = modifiedText
      }
    },
  },
}
</script>

<template>
  <header class="entry_title_home">
    <div id="entry_title_home_image" />
    <div class="entry_title_home_wrapper content_width">
      <div id="entry_title_image_home_wrapper">
        <div id="entry_title_image_home">
          <figure>
            <div class="poids-image">
              <span class="poids-image-icone"><svg
                class="symbole_feuille_nanosite" data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133 133"
              >
                <defs />
                <path
                  class="cls-1"
                  d="M33.24,45.23C-3.76,79.53.87,122.94.87,122.94s49.45,4.81,81.77-20.43c39.74-31,49.68-92.79,49.68-92.79S70.21,10.93,33.24,45.23ZM1.07,121.72s48-52.14,92.35-70.58Z"
                />
              </svg>
              </span><span class="poids-image-data">40 kb</span>
            </div>
            <img :src="optionsPage.image_dentete_onepage" loading="lazy">
          </figure>
        </div>
      </div>
      <div id="entry_title_title_home_wrapper">
        <div id="entry_title_title_home">
          <h1 v-if="optionsPage.accroche_dentete" id="entry_title_home_accroche" ref="h1Title" class="anim_top_bottom" v-html="optionsPage.accroche_dentete" />
          <div id="entry_title_home_description" class="entry-content anim_top_bottom">
            <p
              style="padding-top: 0px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;"
              v-html="optionsPage.phrase_dintroduction_entete"
            />
            <p v-if="optionsPage.call_to_action_texte" class="cta_btn_lead join_cta_align_left cta_primaire cta_btn_lead_nomargintop">
              <a :href="optionsPage.call_to_action_lien" v-html="optionsPage.call_to_action_texte" target="_blank"/> 
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style>
.cls-1 {
  fill: #53b55d;
}
</style>
