<script>
export default {
  name: 'UserMan',
}
</script>

<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M256,238.428c65.734,0,119.214-53.48,119.214-119.214S321.734,0,256,0c-65.735,0-119.214,53.48-119.214,119.214
				S190.265,238.428,256,238.428z"
          />
          <path
            d="M256,253.693c-97.918,0-177.58,79.662-177.58,177.58v61.375c0,10.687,8.664,19.351,19.351,19.351h316.458
				c10.687,0,19.351-8.664,19.351-19.351v-61.375C433.58,333.355,353.918,253.693,256,253.693z"
          />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
</template>
