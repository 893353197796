import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'

import App from './App.vue'
import router from './router/router'

// Import and use your store after creating `app` and `pinia`
import { useMultilingualStore } from './store/MultilingualStore'

export const pinia = createPinia()
const app = createApp(App)
app.use(router)

const head = createHead()

app.use(pinia)
export const useStore = useMultilingualStore()

app.use(head)

app.mount('#app')
