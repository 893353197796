<script>
import { useHead } from '@unhead/vue'
import { mapActions, mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
export default {
  name: 'CookiesBanner',
  data() {
    return {
      showCookiesBanner: false,
      cookieName: String,
      durationAccepted: Number,
      durationRefused: Number,
    }
  },
  computed: {
    ...mapState(useMultilingualStore, ['options', 'lang']),
  },
  watch: {
    options: {
      handler(options) {
        if (options.nom_du_cookie && options.masquer_cookies === 'visible') {
          this.cookieName = options.nom_du_cookie
          this.durationAccepted = options.duree_du_cookies
          this.durationRefused = options.duree_reapparition_bandeau
          this.initialiseCookieBanner()
        }
      },
    },
  },
  methods: {
    ...mapActions(useMultilingualStore, ['removeScriptTagsFromCookies']),

    injectCookies() {
      this.removeScriptTagsFromCookies()
      useHead({
        script: [
          {
            children: this.options.google_analytics,
          },
        ],
      })
      const body = document.querySelector('body')
      const cookieNoScript = new DOMParser().parseFromString(this.options.google_analytics_noscript, 'text/html')
      const noScript = document.createElement('noscript')
      noScript.appendChild(cookieNoScript.querySelector('iframe'))
      body.prepend(noScript)

      // body.prepend(cookieNoScript.documentElement)
    },
    acceptAll() {
      this.createCookie(this.cookieName, true, this.durationAccepted)
      this.createCookie('facebookCookie', true, this.durationAccepted)
      this.createCookie('googleAnalyticsCookie', true, this.durationAccepted)
      this.createCookie('googleAdsCookie', true, this.durationAccepted)
      this.closeBanner()
      this.injectCookies()
    },
    checkCookie(name) {
      const nameEQ = `${name}=`
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length)
        }
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length)
      }
      return null
    },
    closeBanner() {
      this.showCookiesBanner = false
    },
    createCookie(name, value, duration) {
      value = value ? 'accepted' : 'refused'
      let expires
      if (duration) {
        const date = new Date()
        date.setTime(date.getTime() + (duration * 24 * 60 * 60 * 1000))
        expires = `; expires=${date.toGMTString()}`
      }
      else {
        expires = ''
      }
      document.cookie = `${name}=${value}${expires}; path=/`
    },
    initialiseCookieBanner() {
      if (!this.checkCookie(this.cookieName)) {
        this.showCookiesBanner = true
      }
      else {
        this.closeBanner()
        if (this.checkCookie(this.cookieName) === 'accepted') {
          this.injectCookies()
        }
      }
    },
    refuse() {
      this.createCookie(this.cookieName, false, this.durationRefused)
      this.closeBanner()
    },
  },
}
</script>

<template>
  <div v-if="showCookiesBanner">
    <div id="bandeau_cookies" tabindex="1">
      <a tabindex="0" href="#global_content" class="skip_to_global_content">Passer au contenu principal</a>
      <div id="bandeau_content">
        <p id="ligne_1_bandeau_cookies" tabindex="0" class="texte_bandeau_cookies">
          {{ options.texte_du_bandeau_cookies_ligne_1 }}
        </p>
        <router-link
          v-if="lang"
          id="lien_texte_bandeau_cookie_ligne_2"
          target="_blank"
          :to="{
            name: 'Principale',
            params: { lang, page: 'legal-information' },
          }"
        >
          <p class="texte_bandeau_cookies">
            {{ options.texte_du_bandeau_cookies_ligne_2 }}
          </p>
        </router-link>
        <p id="boutons_bandeau" class="cta_btn_lead">
          <a id="refuser_cookies" tabindex="0" @click="refuse">{{ options.refuser_cookies }}</a><a id="accepter_cookies" tabindex="0" @click="acceptAll">{{ options.accepter_tous_cookies }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

 <style>
#wcb.carbonbadge{--b1:#0e11a8;
  --b2:#00ffbc;
  font-size:15px;
  text-align:center;color:var(--b1);
  line-height:1.15}
  #wcb.carbonbadge sub{
    vertical-align:middle;
    position:relative;
    top:.3em;font-size:.7em}
    #wcb #wcb_2,#wcb #wcb_a,#wcb #wcb_g{
      display:inline-flex;
      justify-content:center;
      align-items:center;
      text-align:center;
      font-size:1em;
      line-height:1.15;
      font-family:-apple-system,BlinkMacSystemFont,sans-serif;
      text-decoration:none;
      margin:.2em 0}
      #wcb #wcb_a,#wcb #wcb_g{
        padding:.3em .5em;border:.13em solid var(--b2)}
      #wcb #wcb_g{
        border-radius:.3em 0 0 .3em;
        background:#fff;
        border-right:0;
        min-width:8.2em
      }
        #wcb #wcb_a {
          border-radius:0 .3em .3em 0;
          border-left:0;
          background:var(--b1);color:#fff;
          font-weight:700;border-color:var(--b1)
        }
            #wcb.wcb-d #wcb_a{
              color:var(--b1);
              background:var(--b2);
              border-color:var(--b2)}
              #wcb.wcb-d #wcb_2{
                color:#fff
              }
.nanosite_label_bg {
            fill: #e9f5ea;
            }

            #nanosite_label_off .cls-1,
            #nanosite_label_off .cls-2 {
            fill: #53b55d;
            }

            #nanosite_label_off .cls-1 {
            opacity: 0.3;
            }
</style>

