<script>
import { mapState, mapActions } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'

export default {
  name: 'FooterPart',
  data() {
    return {
      showWeight: false,
      permalinksReady: false,
    }
  },
  computed: {
    ...mapState(useMultilingualStore, ['options', 'menuFooter', 'lang', 'translatedPermalinks']),
    footerLinks() {
      const links = [
        { slug: 'legal-information', text: this.options.legal_notice_texte },
        { slug: 'privacy-notice', text: this.options.privacy_notice_text },
        { slug: 'sitemap', text: this.options.sitemap_texte },
      ]
      return links.map(link => ({
        ...link,
        translatedPermalink: this.getTranslatedPermalink(link.slug)
      }))
    }
  },
  methods: {
    ...mapActions(useMultilingualStore, ['getTranslatedPermalink', 'getTranslatedPermalinks']),
    currentDate() {
      const current = new Date()
      return current.getFullYear()
    },
    backToTop() {
      window.scrollTo({
        top: 0,
        x: null,
        behavior: 'smooth',
      })
    },
    async loadPermalinks() {
      await this.getTranslatedPermalinks()
      //console.log('Permalinks loaded for footer:', this.translatedPermalinks)
      this.permalinksReady = true
    }
  },
  created() {
    this.loadPermalinks()
  }
}
</script>

<template>
  <footer id="footer" class="content_width">
    <div class=" footer-left-part">
      <div id="footer_wrapper">
        <p id="footer_content" class="legende">
          © {{ currentDate() }} {{ options.nom_du_site }}
        </p>
      </div>
    </div>

    <div v-if="permalinksReady" class="footer-right-part">
      <div v-for="link in footerLinks" :key="link.slug" class="link-footer">
        <router-link :to="link.translatedPermalink">
          {{ link.text }}
        </router-link>
      </div>
    </div>

    <div id="retourenhaut" style="opacity: 1; transition: opacity 0.5s ease 0s;">
      <svg id="retourenhaut_svg" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" @click="backToTop">
        <rect class="cls-1" width="40" height="40" />
        <circle id="circle_base" class="cls-2" cx="20" cy="20" r="18" />
        <g>
          <line class="cls-3" x1="20" y1="30.67" x2="20" y2="10.67" />
          <polyline class="cls-3" points="12.46 18.21 20 10.67 27.54 18.21" />
        </g>
        <circle id="circle_progress" class="cls-3" cx="20" cy="20" r="18" transform="rotate(-90 20 20)" />
      </svg>
    </div>
    <div id="footer_carbon">
      <div id="wcb" class="carbonbadge wcb-d">
        <div id="wcb_p">
          <span id="wcb_g">0.03g of CO<sub>2</sub>/view</span><a id="wcb_a" target="_blank" rel="noopener" href="https://websitecarbon.com">Website Carbon</a>
        </div><span id="wcb_2">&nbsp;Cleaner than 97% of pages tested</span>
      </div>
      <!--       <script src="https://unpkg.com/website-carbon-badges@1.1.3/b.min.js" defer="" />
 -->
    </div>

    <div id="nanosite_label" style="opacity: 1; transition: opacity 0.5s ease 0s;">
      <div v-if="showWeight" id="nanosite_label_container" @mouseleave="showWeight = false">
        <p id="nanosite_label_title">
          <strong>Eco designed website</strong>
        </p>
        <p id="nanosite_label_consommation">
          This page consumes<br>
          <span id="nanosite_calcul_ok" style="display: block;" @mouseenter="showWeight = true"><span id="nanosite_calcul_ok_value">0.03</span>g de CO<sup>2</sup> / vue.</span>
          <span id="nanosite_calcul_encours">(Calculation in progress...).</span>
        </p>
      </div>

      <svg id="nanosite_label_off" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 40" @mouseenter="showWeight = true">
        <defs />
        <path class="cls-1" d="M34.08,5.87a19.94,19.94,0,0,1,4.29,21.91,20.13,20.13,0,0,1-4.29,6.34,20.45,20.45,0,0,1-6.36,4.27,20,20,0,1,1,0-36.79,20.62,20.62,0,0,1,6.36,4.27m-9.91,4.2a10.78,10.78,0,1,0,5.74,14.12c.56-1.31,6.46-3.48,6.46-5s.56-3.86,0-5.18-7.79-.67-8.77-1.65a11,11,0,0,0-3.43-2.32" />
        <path class="cls-2" d="M23.87,15.43A19.56,19.56,0,0,0,17.5,30.7s9.71.95,16.07-4c7.81-6.11,9.76-18.24,9.76-18.24S31.13,8.7,23.87,15.43Zm-6.33,15s9.12-9.89,17.53-13.39Z" />
      </svg>

      <svg v-if="showWeight" id="nanosite_label_on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.82 148.31" @mouseenter="showWeight = true">
        <defs />
        <g id="Calque_2" data-name="Calque 2">
          <path class="nanosite_label_bg" d="M9.4,129.36S71.55,168,148.72,135,269.82,8.44,269.82,8.44,169.73-17.31,95.13,21.14,9.4,129.36,9.4,129.36Z" />
        </g>
      </svg>
    </div>
  </footer>
</template>

 <style>
#aside_prefooter {
    background-color: #DFE7ED !important;
}
#nanosite_label_off:hover #nanosite_label_container {
  display: block;
}
#nanosite_label_off:hover #nanosite_label_on {
  display: block;
}
/* #nanosite_label_container:hover, #nanosite_label_on:hover {
  display: block;
} */
#wcb.carbonbadge{--b1:#0e11a8;
  --b2:#00ffbc;
  font-size:15px;
  text-align:center;color:var(--b1);
  line-height:1.15}
  #wcb.carbonbadge sub{
    vertical-align:middle;
    position:relative;
    top:.3em;font-size:.7em}
    #wcb #wcb_2,#wcb #wcb_a,#wcb #wcb_g{
      display:inline-flex;
      justify-content:center;
      align-items:center;
      text-align:center;
      font-size:1em;
      line-height:1.15;
      font-family:-apple-system,BlinkMacSystemFont,sans-serif;
      text-decoration:none;
      margin:.2em 0}
      #wcb #wcb_a,#wcb #wcb_g{
        padding:.3em .5em;border:.13em solid var(--b2)}
      #wcb #wcb_g{
        border-radius:.3em 0 0 .3em;
        background:#fff;
        border-right:0;
        min-width:8.2em
      }
        #wcb #wcb_a {
          border-radius:0 .3em .3em 0;
          border-left:0;
          background:var(--b1);color:#fff;
          font-weight:700;border-color:var(--b1)
        }
            #wcb.wcb-d #wcb_a{
              color:var(--b1);
              background:var(--b2);
              border-color:var(--b2)}
              #wcb.wcb-d #wcb_2{
                color:#fff
              }
.nanosite_label_bg {
            fill: #e9f5ea;
            }

            #nanosite_label_off .cls-1,
            #nanosite_label_off .cls-2 {
            fill: #53b55d;
            }

            #nanosite_label_off .cls-1 {
            opacity: 0.3;
            }
</style>
