<script>
import { mapActions, mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
export default {
  name: 'NavBlog',
  computed: {
    ...mapState(useMultilingualStore, ['options', 'posts', 'lang', 'id', 'prevPost', 'nextPost']),
    backTo() {
      let textButtonBackTo = ''
      switch (this.$route.params.page) {
        case 'esg-talks':
          textButtonBackTo = this.options.texte_bouton_de_retour_esg_talks
          break
        case 'news':
          textButtonBackTo = this.options.texte_bouton_de_retour_blog
          break
        case 'courses':
          textButtonBackTo = this.options.texte_bouton_de_retour_cours
          break
        default:
          textButtonBackTo = this.options.texte_bouton_de_retour_blog
          break
      }
      return textButtonBackTo
    },
  },

  watch: {
    id: {
      handler(val) {
        if (val) {
          this.updatePrevAndNext(this.$route.params.page)
        }
      },
    },
  },
  methods: {
    ...mapActions(useMultilingualStore, ['updatePrevAndNext']),
  },
}
</script>

<template>
  <div class="nav_blog_wrapper">
    <nav id="nav_blog" class="content_width">
      <div id="nav_blog_left" class="nav_blog_item">
        <router-link
          v-if="prevPost.exist"
          aria-hidden="true"
          class="nav_blog_link_item"
          :to="{
            name: 'Principale',
            params: { lang, page: $route.params.page, subpage: prevPost.slug },
          }"
        >
          <p class="nav_blog_prevnext legende">
            <svg class="svg svg-nav" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" /></svg> <span>{{ options.blog_precedent }}</span>
          </p>
          <p v-if="prevPost.exist" class="nav_blog_title">
            {{ prevPost.name }}
          </p>
        </router-link>
      </div>
      <div id="nav_blog_center" class="nav_blog_item">
        <router-link
          aria-hidden="true"
          class="nav_blog_link_item"
          :to="{
            name: 'Principale',
            params: { lang, page: $route.params.page },
          }"
        >
          <span>{{ options.blog_retour }} <br>{{ backTo }}</span>
        </router-link>
      </div>
      <div id="nav_blog_right" class="nav_blog_item">
        <router-link
          v-if="nextPost.exist"
          aria-hidden="true"
          class="nav_blog_link_item"
          :to="{
            name: 'Principale',
            params: { lang, page: $route.params.page, subpage: nextPost.slug },
          }"
        >
          <p class="nav_blog_prevnext legende">
            <span>{{ options.blog_suivant }}</span> <svg class="svg svg-nav" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />
            </svg>
          </p>
          <p v-if="nextPost.exist" class="nav_blog_title">
            {{ nextPost.name }}
          </p>
        </router-link>
      </div>
    </nav>
  </div>
</template>

<style>

</style>
