<script>
import { mapActions, mapState } from 'pinia'
import { useMultilingualStore } from '@/store/MultilingualStore'
import { postMailViaContactFormApi } from '@/utils/postMailViaContactFormApi'

export default {
  name: 'ContactForm',
  data() {
    return {
      formID: 15622,
      showError: false,
      showSuccess: false,
      privacyPolivyApproved: false,
      errorPrivacyPolicy: false,
      forbiddenFieldChanged: false,
      showErrorCaptcha: false,
      showCaptcha: false,
    }
  },
  computed: {
    ...mapState(useMultilingualStore, [
      'captchaAnswer',
      'contactFormData',
      'contactFormFields',
      'website',
      'options',
      'protocol'
    ]),
  },
  created() {
    this.getContactFormFields(this.formID)
  },
  updated() {
    if (this.$refs.inputs !== undefined) {
      this.showCaptcha = true
    }
  },
  methods: {
    ...mapActions(useMultilingualStore, [
      'fillCaptcha',
      'getContactFormFields',
      'emptyContactFormFields',
      'fillContactFormData'
    ]),

    handleClickPrivacyPolice() {
      this.privacyPolivyApproved = !this.privacyPolivyApproved
      if (this.privacyPolivyApproved && this.errorPrivacyPolicy) {
        this.errorPrivacyPolicy = false
      }
    },

    pattern(val) {
      if (val === 'your-email') {
        return '[^@\\s]+@[^@\\s]+\\.[^@\\s]+'
      }
      return null
    },
    
    typeInput(val) {
      if (val === 'your-email') {
        return 'email'
      }
      return 'text'
    },

    handleBlankFields() {
      this.$refs.form.querySelectorAll('input, textarea').forEach((input) => {
        const field = this.contactFormFields.find(field => field.name === input.name);
        if (field && field.required && (!input.value || input.value.trim() === '')) {
          this.handleBlankField(input);
        } else {
          this.removeBlankFieldState(input);
        }
      });
    },

    handleBlankField(input) {
      input.classList.add('blank-field');
      input.setAttribute('placeholder', this.options.empty_input_error_message);
    },

    removeBlankFieldState(input) {
      input.classList.remove('blank-field');
      input.removeAttribute('placeholder');
    },

    handleBlur(e) {
      const field = this.contactFormFields.find(field => field.name === e.target.name);
      if (field && field.required && (!e.target.value || e.target.value.trim() === '')) {
        this.handleBlankField(e.target);
      } else {
        this.removeBlankFieldState(e.target);
      }
    },

    handleKeyup(e) {
      const key = e.target.name
      const value = e.target.value.trim()
    
      if (key === 'your-email') {
        if (value === '') {
          e.target.setCustomValidity("") // Email vide est autorisé
        } else {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          if (!emailRegex.test(value)) {
            e.target.setCustomValidity("Veuillez saisir une adresse e-mail valide.")
          } else {
            e.target.setCustomValidity("")
          }
        }
      }
      this.removeBlankFieldState(e.target);
      
      // Mettre à jour les données du formulaire
      this.contactFormData = { ...this.contactFormData, [key]: value };
    },

    validateForm() {
      let isValid = true;
      this.$refs.form.querySelectorAll('input, textarea').forEach((input) => {
        if (input.name === 'your-email') {
          if (input.value.trim() !== '') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (!emailRegex.test(input.value.trim())) {
              isValid = false;
              this.handleBlankField(input);
              input.setCustomValidity("Veuillez saisir une adresse e-mail valide.")
            } else {
              input.setCustomValidity("")
            }
          } else {
            input.setCustomValidity("") // Email vide est autorisé
          }
        } else if (input.required && input.value.trim() === '') {
          isValid = false;
          this.handleBlankField(input);
          input.setCustomValidity("Ce champ est requis.")
        } else {
          input.setCustomValidity("")
        }
      });
      return isValid;
    },

    checkForm(e) {
      e.preventDefault()
      
      if (this.privacyPolivyApproved && !this.forbiddenFieldChanged && this.captchaAnswer === '4' && this.validateForm()) {
        const route = `${this.protocol + this.website}/wp-json/contact-form-7/v1/contact-forms/${this.formID}/feedback`
        
        // Créez un nouvel objet pour les données du formulaire
        const formData = new FormData(this.$refs.form);
        
        // Ajoutez les champs requis par Contact Form 7
        formData.append('_wpcf7', this.formID);
        formData.append('_wpcf7_version', '5.4');
        formData.append('_wpcf7_locale', 'en_US');
        formData.append('_wpcf7_unit_tag', `wpcf7-f${this.formID}-p${this.formID}-o1`);
        formData.append('_wpcf7_container_post', '0');

        console.log('Form data before sending:', Object.fromEntries(formData));
        
        postMailViaContactFormApi(route, 'POST', formData).then((value) => {
          console.log('Response:', value);
          if (value.status === 'validation_failed') {
            this.showError = true;
            this.handleBlankFields();
            if (value.invalid_fields) {
              value.invalid_fields.forEach(field => {
                const input = this.$refs.form.querySelector(`[name="${field.field}"]`);
                if (input) {
                  this.handleBlankField(input);
                  input.setCustomValidity(field.message);
                }
              });
            }
          } else if (value.status === 'mail_sent') {
            this.showError = false;
            this.showSuccess = true;
            this.emptyContactFormFields();
            this.resetForm();
          } else {
            console.error('Unexpected status:', value.status);
            this.showError = true;
          }
        }).catch(error => {
          console.error('Error sending form:', error);
          this.showError = true;
        });
      } else {
        this.showError = true;
        if (this.captchaAnswer !== '4') {
          this.showErrorCaptcha = true;
        }
        if (!this.privacyPolivyApproved) {
          this.errorPrivacyPolicy = true;
        }
        if (!this.validateForm()) {
          this.handleBlankFields();
        }
      }
    },

    resetForm() {
      this.$refs.form.reset()
      this.$refs.form.querySelectorAll('input, textarea').forEach(input => {
        this.removeBlankFieldState(input)
        input.setCustomValidity('')
      })
      this.showErrorCaptcha = false
      this.errorPrivacyPolicy = false
      this.privacyPolivyApproved = false
      this.forbiddenFieldChanged = false
    },

    fieldLabel(label, options) {
      switch (label) {
        case 'your-firstname': return options.firstname
        case 'your-name': return options.surname
        case 'your-email': return options.email_address
        case 'your-subject': return options.subject
        case 'your-message': return options.message
        default: return 'field-name'
      }
    },
  },
}
</script>

<template>
  <br>
  <br>
  <br>
  <div v-if="showSuccess" class="successMessage">
    {{ options.success_message }}
  </div>

  <form
    ref="form"
    class="form"
    action="" method="post" @submit="checkForm"
    novalidate
  >
    <p v-for="field in contactFormFields" ref="inputs" :key="field.name" :class="field.name">
      <label :for="field.name">{{ fieldLabel(field.label, options) }}<span v-if="field.required" style="color:#0071BA">&nbsp;*</span></label>
      <input
        v-if="field.name !== 'your-message' && field.name !== 'your-email'"
        :id="field.name"
        :type="typeInput(field.name)"
        :name="field.name"
        :required="field.required"
        @keyup="handleKeyup"
        @blur="handleBlur"
      >
      <input
        v-else-if="field.name === 'your-email'"
        :id="field.name"
        type="email"
        :name="field.name"
        :required="field.required"
        @keyup="handleKeyup"
        @blur="handleBlur"
      >
      <textarea
        v-else
        :id="field.name"
        :name="field.name"
        :required="field.required"
        @keyup="handleKeyup"
        @blur="handleBlur"
      ></textarea>
    </p>
    <p v-if="!showSuccess && showCaptcha" class="captcha">
      <label for="captcha">2 + 2 = </label>
      <input
        id="captcha"
        ref="captcha" :class="showErrorCaptcha ? 'error-captcha' : ''" name="captcha" type="text" @change="(e) => {
          fillCaptcha(e.target.value)
        }"
      >
    </p>

    <div v-if="showError" class="errorMessage">
      {{ options.error_message }}
    </div>
    <div v-if="!showSuccess && showCaptcha">
      <input type="checkbox" class="checkboxPrivacy" @click="handleClickPrivacyPolice"><span :class="errorPrivacyPolicy ? 'error-privacy-policy checkboxPrivacyText' : 'checkboxPrivacyText'">{{ options.privacy_policy_and_legal_notice_button_text }}</span>
      <div class="privacyPolicyText">
        {{ options.privacy_policy_and_legal_notice_text }}
      </div>
      <input type="text" class="forbiddenField" @change="forbiddenFieldChanged = true"> <!-- Champ pour les robots -->
      <input
        type="submit" class="submit" :value="options.submit_button_text"
      >
    </div>
  </form>
</template>

<style>
.forbiddenField {
  visibility: hidden
}
.error-privacy-policy {
  color: red;
  font-weight: 600;
}
.blank-field , .error-captcha{
  background-color: rgb(202, 156, 156);
}
.privacyPolicyText, .checkboxPrivacyText, .checkboxPrivacy {
  margin-top: 1rem;
}
.checkboxPrivacyTex {
  margin-left: 1rem;
}

.errorMessage, .successMessage {
  width: 100%;
  margin: auto;
  text-align: center;
}
.errorMessage {
  color: red;
  font-weight: 600;
}
.form {
/* background-color: rgb(228, 179, 179); */
width: 70%;
margin: auto;
display: flex;
flex-wrap: wrap;
}
.form input {
   /* background-color: red */
}
.form p {
  width: 50%;
}

.form p {
  display: flex;
  flex-direction: column;
}

.form .your-subject, .form .your-message {
  width: 100%;
}

.form .your-message input {
  height: 5rem;
}

.form [name=your-firstname], .form [name=your-email], .form [name=captcha] {
  margin-right: 1rem;
}

.form [name=your-firstname], .form [name=your-email], .form [name=your-name], .form [name=your-subject], .form [name=captcha] {
  height: 50px;
  font-size: 16px;
}

.form [name=your-firstname], .form [name=your-email], .form [name=your-name], .form [name=your-subject], .form textarea, .form [name=captcha] {
 border-color: #0071BA !important;
}

.form input, .form textarea {
  border-radius: 5px !important;
  border: 2px solid;
}

.form textarea {
  min-height: 150px;
}

.form [type=submit] {
  background-color: #1E3288;
  color: white;
  border-radius: 25px !important;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: auto;
  margin-top: 1rem;
  display: flex;
}

.your-name, .your-firstname, .your-email, .your-subject, .your-message, .captcha {
  margin-top: 1rem;
}
</style>
