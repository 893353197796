<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'

export default {
  name: 'BandeauHeader',
  props: ['route'],
  data: () => ({
    useSubpageBreadcrumb: false,
    useDefaultBreadcrumb: false,
  }),
  computed: {
    ...mapState(useMultilingualStore, ['searchQuery', 'title', 'currentWebsite', 'options', 'esgTalks', 'lang', 'pageSlug']),
    pageForBreadcrumb(value) {
      return value
    },
    subpageForBreadcrumb(value) {
      return value
    },
    parentPage() {
      let parentPageName = ''
      switch (this.route.params.page) {
        case 'esg-talks':
          parentPageName = 'ESG Talks'
          break
        case 'news':
          parentPageName = this.options.titre_de_la_page_actualites
          break
        default:
          parentPageName = this.route.params.page
          break
      }
      return parentPageName
    },
  },
  watch: {

  },
  created() {
    if (this.route.params.subpage) {
      this.useSubpageBreadcrumb = true
      // console.log('utilisation du breadcrumb pour les sous-pages')
    }
    else {
      this.useDefaultBreadcrumb = true
      // console.log('utilisation du breadcrumb par défaut')
    }
  },

  mounted() {
    if (this.title) {
      this.modifyLastCharacterOfTitle()
    }
  },

  updated() {
    if (this.title) {
      this.modifyLastCharacterOfTitle()
    }
  },

  methods: {
    modifyLastCharacterOfTitle() {
      const title = this.$refs.h1Title
      const textContent = title.textContent
      const lastCharIndex = textContent.length - 1
      if (textContent.charAt(lastCharIndex) === '.') {
        const modifiedText = `${textContent.substring(0, lastCharIndex)}<span style="color:#0071BA">.</span>`
        title.innerHTML = modifiedText
      }
    },
  },

}
</script>

<template>
  <header class="entry_title">
    <!-- Page simple -->
    <nav v-if="useDefaultBreadcrumb" class="breadcrumb_top content_width">
      <p class="legende">
        <a :href="currentWebsite">{{ options.fil_ariane_accueil }}</a><span class="breadcrumb_separator separator_current">›</span><span id="bc-location" class="capitalize-first-letter" aria-current="location">{{ title }}</span>
      </p>
    </nav>

    <!-- Page simple avec page parente -->
    <nav v-if="useSubpageBreadcrumb" class="breadcrumb_top content_width">
      <p class="legende">
        <a :href="currentWebsite">{{ options.fil_ariane_accueil }}</a><span class="breadcrumb_separator">›</span>

        <router-link
          v-if="$route.params.subpage"
          :to="{
            name: 'Principale',
            params: { lang, page: $route.params.page },
          }"
        >
          {{ parentPage }}
        </router-link>
        <span class="breadcrumb_separator separator_current">›</span><span id="bc-location" aria-current="location" class="capitalize-first-letter">{{ title }}</span>
      </p>
    </nav>

    <router-link
      v-if="$route.params.subpage"
      class="return-to-courses"
      :to="{
        name: 'Principale',
        params: { lang, page: $route.params.page },
      }"
    >
      &lt;<span class="capitalize-first-letter">
        &nbsp;{{ parentPage }}
      </span>
    </router-link>

    <div v-if="pageSlug !== 'search'" class="content_width bandeau-header">
      <h1 ref="h1Title">
        {{ title }}
      </h1>
    </div>
    <div v-else>
      <h1 ref="h1Title">
        {{ options.rechercher_sur_le_site_resultats }} {{ searchQuery }}
      </h1>
    </div>
  </header>
</template>

<style>
.cls-1 {
  fill: #53b55d;
}
.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}
</style>

