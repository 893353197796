<script>
import { mapActions, mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
import DownArrow from '../svg/DownArrow.vue'
export default {
  name: 'SelectLang',
  components: {
    DownArrow,
  },

  emits: ['selectLangLoaded'],
  computed: {
    ...mapState(useMultilingualStore, ['lang', 'languages', 'searchQuery']),
  },
  watch: {
    lang: {
      handler(val) {
        this.getOptions(val)
      },
    },
  },
  created() {
    // this.langUser = navigator.language.slice(0, 2)
    this.getOptions(this.lang) // récupère la page des options de ACF pour charger différents éléments de la page (header, footer et liens pour le fil d'ariane)
  },
  updated() {
    this.$emit('selectLangLoaded', this.$refs)
  },
  methods: {
    ...mapActions(useMultilingualStore, ['setLang', 'getOptions', 'changeOrder', 'getPosts', 'updateSearchBoxState', 'search', 'getData']),
    /**
     * Modifie la langue dans le routeur tout en conservant les bons autres paramètres
     * La fonction est adaptées à certaines pages particulière, notamment la page avec les actu
     * ou la page consacrée aux résultats de recherche
     */
    changeLangInRoute() {
      // console.log('Changement de la langue dans la route')
      if (this.$route.name === 'Search') {
        return this.$router.push({
          name: 'Principale',
          params: { lang: this.lang, page: 'home', subpage: '', searchQuery: '' },
        })
      }
      if (this.$route.params.page === 'news') {
        return this.$router.push({
          name: 'Principale',
          params: { lang: this.lang, page: this.$route.params.page, subpage: '', searchQuery: '' },
        })
      }
      else if (this.$route.params.page === 'search') {
        return this.$router.push({
          name: 'Principale',
          params: { lang: this.lang, page: this.$route.params.page, subpage: '', searchQuery: this.$route.params.searchQuery },
        })
      }
      else {
        return this.$router.push({
          name: 'Principale',
          params: { lang: this.lang, page: this.$route.params.page, subpage: this.$route.params.subpage, searchQuery: '' },
        })
      }
    },
    /**
     * Fonction lançant les différentes fonctions nécessaires au changement de langue sur le site
     * @param {string} langSelected
     */
    handleClickSelectLang(langSelected) {
      // console.log('Clic sur une langue : ', langSelected)
      /**
       * Modification interface langue
       */
      this.setLang(langSelected)
      this.changeLangInRoute()
      this.changeOrder()
      this.getPosts()
    },

    handleTouchSelectLang() {
      const dropdown = this.$refs.selectLang.querySelector('.dropdown')

      // Ajoutez les propriétés de transition
      dropdown.style.transitionProperty = 'height'
      dropdown.style.transitionDuration = '0.2s'

      // Modifiez la hauteur
      dropdown.style.height = '12rem'
    },
  },
}
</script>

<template>
  <div id="multilingue_wrapper" ref="selectLang" class="relative" @touchstart="handleTouchSelectLang">
    <div class="absolute multilingue">
      <div class="dropdown absolute">
        <div class="relative in-dropdown-1">
          <div class="absolute in-dropdown-2">
            <div class="relative in-dropdown-3">
              <ul class="content_hide_menu content_hide_menu_search">
                <li
                  v-for="option in languages" :key="option"
                  :class="`content_hide_menu content_hide_menu_search ${option === lang ? ' current-lang' : ''}`"
                  :value="option" :selected="option === lang" @click.stop="handleClickSelectLang(option)"
                >
                  <a>
                    {{ option }} <DownArrow v-if="option === lang" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
