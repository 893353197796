<script>
import { mapActions, mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
export default {
  name: 'SearchBox',
  data() {
    return {
      searchQuery: '',
    }
  },
  computed: {
    ...mapState(useMultilingualStore, ['options', 'lang']),
  },
  watch: {

  },
  methods: {
    ...mapActions(useMultilingualStore, ['search', 'updateSearchBoxState', 'recordSearchQuery']),
    handleSearchForm(e) {
      // console.log('Search box: heandlesearchform')
      e.preventDefault()
      this.updateSearchBoxState()
      this.$router.push({
        name: 'Search',
        params: { page: 'search', lang: this.lang, searchQuery: this.searchQuery },
      })
    },
  },
}
</script>

<template>
  <div id="search_box" class="multilingue_header" style="top: 100px; display: block;">
    <div id="search_box_wrapper" class="content_width">
      <label for="s" style="opacity: 0; height: 0"> Search the site</label>
      <form @submit="handleSearchForm">
        <div>
          <input id="s" v-model="searchQuery" type="text" name="s" placeholder=" Search the site">
          <input id="searchsubmit" type="submit" value="Rechercher">
        </div>
      </form>
      <div id="search_input_trigger" @click="handleSearchForm">
        <svg id="search_icon_opened" class="search_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
          <circle class="search_icon_cls" cx="33.97" cy="34.97" r="28.47" />
          <line class="search_icon_cls" x1="74" y1="75" x2="53.9" y2="54.9" />
        </svg>
      </div>
    </div>
  </div>
</template>

<style>

</style>
