<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
export default {
  name: 'NotFound',
  computed: {
    ...mapState(useMultilingualStore, ['options', 'protocol', 'website']),
  },

}
</script>

<template>
  <div class="content_width bandeau-header">
    <h1>{{ options.page_404_titre }}</h1>
  </div>
  <div class="content_width entry-content padding_section">
    <p style="text-align: center; padding-top: 0px; margin-top: 0px;">
      {{ options.page_404_texte1 }}
    </p>
    <p style="text-align: center; padding-bottom: 0px; margin-bottom: 0px;">
      {{ options.page_404_texte2 }} <a :href="`${protocol + website}`">{{ options.page_404_texte2_lien }}</a>.
    </p>
  </div>
</template>

<style>

</style>
