<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
import UserMan from '../svg/UserMan.vue'

export default {
  name: 'ButtonLogin',

  components: { UserMan },
  emits: ['buttonLoginLoaded'],
  data() {
    return {
      showText: true,
      clientWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapState(useMultilingualStore, ['options']),
  },
  watch: {
    clientWidth(val) {
      return this.showText = val > 700
    },
  },
  mounted() {
    this.checkWindowSizeOnMounted()
    window.addEventListener('resize', () => {
      this.clientWidth = window.innerWidth
    })
  },
  methods: {
    checkWindowSizeOnMounted() {
      this.showText = window.innerWidth > 700
    },
  },
}
</script>

<template>
  <div id="header_phone" ref="buttonLogin" class="content_hide_menu content_hide_menu_search">
    <div class="cta_btn_lead">
      <a :class="[showText ? '' : 'round']" :href="options.url_du_bouton_login">
        <span class="user-man-svg">
          <UserMan />
        </span>
        <span v-if="showText"> {{ options.texte_du_bouton_login }} </span>
      </a>
    </div>
  </div>
</template>

<style scoped>
.round {
  padding: 1rem 1rem 1rem 1rem !important
}
.round svg {
  position: relative !important;
  left: 0px !important;
}
.round .user-man-svg {
  margin-right: 0 !important;
}
</style>
