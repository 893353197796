<script>
export default {
  name: 'SearchiconClose',
}
</script>

<template>
  <svg
    id="search_icon_close"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
    style="display: block;"
  >
    <line class="search_icon_cls" x1="74" y1="75" x2="5.5" y2="6.5" />
    <line class="search_icon_cls" x1="5.5" y1="75" x2="74" y2="6.5" />
  </svg>
</template>
