import { createRouter, createWebHistory } from 'vue-router'
import RenderPage from '../pages/RenderPage.vue'

const routes = [
  {
    name: 'Principale',
    path: '/:lang?/:page?/:subpage?',
    component: RenderPage,
  },
  {
    name: 'Search',
    path: '/:lang?/:page?/:searchQuery?',
    component: RenderPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Ajoutons un guard de navigation global pour logger les changements de route
router.beforeEach((to, from, next) => {
  //console.log('Navigation to:', to.fullPath);
  next();
});

export function useRouter() {
  return router
}

export default router
