export function postMailViaContactFormApi(route, method, formData) {
  return fetch(route, {
    method: method,
    body: formData,
    headers: {
      'Accept': 'application/json',
    },
  })
  .then(response => {
    if (!response.ok) {
      return response.text().then(text => {
        throw new Error(`HTTP error! status: ${response.status}, body: ${text}`);
      });
    }
    return response.json();
  })
  .catch(error => {
    console.error('Error:', error);
    return { status: 'error', message: error.message };
  });
}