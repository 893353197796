<script>
import { mapState, mapActions } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'

export default {
  name: 'NewsPage',
  computed: {
    ...mapState(useMultilingualStore, ['esgTalks', 'options', 'lang', 'posts']),
  },
  methods: {
    ...mapActions(useMultilingualStore, ['getPosts']),
  },
  async created() {
    if (this.posts.length === 0) {
      await this.getPosts()
    }
  },
}
</script>

<template>
  <div class="padding_section_bottom padding_section_top">
    <div class="intro-news">
      {{ options.intro_news }}
    </div>
    <div id="aside_actualites" class="content_width actualites_page_content">
      <div id="aside_actualites_wrapper">
        <article v-for="post in posts" id="post-15515" :key="post.id" class="article_extrait post-15515 post type-post status-publish format-standard hentry category-actualites article_extrait_1">
          <div class="article_extrait_thumbnail">
            <a href="https://academy.candriam.com/en/publications/voting-and-engagement-pillars-of-sustainable-investing/" class="image_article_wrapper">
              <figure>
                <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="" alt="">
              </figure>
            </a>
          </div>
          <div class="article_extrait_wrapper_text">
            <p class="entry_title_date legende">
              <span class="date_jour">{{ post.date.day }}</span><span class="date_mois">{{ post.date.month }}</span><span class="date_annee">{{ post.date.year }}</span>
            </p>
            <h2 class="article_extrait_post_title">
              <router-link
                :to="{
                  name: 'Principale',
                  params: { lang, page: 'news', subpage: post.slug },
                }"
              >
                {{
                  post.title.rendered
                }}
              </router-link>
            </h2>
            <p>{{ post.acf.description_extrait_de_la_page }}</p>
          </div>

          <router-link
            class="btn btn_article_extrait"
            aria-hidden="true"
            :to="{
              name: 'Principale',
              params: { lang, page: 'news', subpage: post.slug },
            }"
          >
            {{ options['call-to-action_des_extraits_darticles'] }}
          </router-link>
        </article>
      </div>
    </div>
  </div>
</template>

<style>

</style>
