export function blocQuestionReponse() {
  const questionReponseTitles = document.querySelectorAll(
    '.question_reponse_title',
  )

  if (questionReponseTitles.length > 0) {
    questionReponseTitles.forEach((questionReponseTitle) => {
      questionReponseTitle.addEventListener('click', (e) => {
        const plus = questionReponseTitle.querySelector(
          '.question_reponse_title_icone_plus',
        )
        const moins = questionReponseTitle.querySelector(
          '.question_reponse_title_icone_moins',
        )
        let reponse = questionReponseTitle.closest('.question_reponse_item')
        reponse = reponse.querySelector('.question_reponse_wysiwyg')
        if (questionReponseTitle.classList.contains('active')) {
          // est déjà actif
          questionReponseTitle.classList.remove('active')
          plus.style.display = 'block'
          moins.style.display = 'none'
          reponse.style.display = 'none'
        }
        else {
          // est inactif
          questionReponseTitle.classList.add('active')
          plus.style.display = 'none'
          moins.style.display = 'block'
          reponse.style.display = 'block'
        }
      })
    })
  }
}
