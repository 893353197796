export async function getApiData(route, params = { method: 'get' }) {
  try {
    const response = await fetch(route, {
      method: params.method,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const body = await response.json()
    return body
  }
  catch (error) {
    console.log('Error fetching data:', error, route)
    throw error // Propager l'erreur pour une gestion ultérieure
  }
}
