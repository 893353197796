<script>
/* import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore' */

import { mapState } from 'pinia'
import { useMultilingualStore } from '@/store/MultilingualStore'

export default {
  name: 'SearchResults',
  computed: {
    ...mapState(useMultilingualStore, ['searchResults', 'website']),
  },
  watch: {
    searchResult: {
      handler(results) {
        // console.log('WATCH : ', results)
      },
    },
  },
  created() {
    // console.log('CREATED : ', this.searchResults)
  },
  mounted() {
    // console.log('MOUNTED : ', this.searchResults)
  },
  updated() {
    // console.log('UPDATED : ', this.searchResults)
    this.changeHyperlink()
  },
  methods: {
    changeHyperlink() {
      const links = document.querySelectorAll('a')
      links.forEach((link) => {
        if (link.host === this.website) {
          if (!link.pathname.startsWith('/wp-content/uploads')) {
            link.host = window.location.host
          }
        }
        if (link.protocol !== window.location.protocol) {
          link.protocol = window.location.protocol
        }
      })
    },
  },
}
</script>

<template>
  <div v-for="searchResult in searchResults" :key="searchResult.id" class="padding_section">
    <div class="content_width">
      <div class="width_700">
        <div class="search_result entry-content">
          <h2 style="padding-top: 0px; margin-top: 0px;">
            <a :href="searchResult.url">{{ searchResult.title }}</a>
          </h2>
          <p style="padding-bottom: 0px; margin-bottom: 0px;">
            {{ searchResult.description }}
          </p>
        </div>
        <div class="remove_margin" />
      </div>
    </div>
  </div>
</template>

<style>

</style>
