<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
export default {
  name: 'AsideActualites',
  computed: {
    ...mapState(useMultilingualStore, ['options', 'posts', 'lang']),
  },
}
</script>

<template>
  <aside id="aside_actualites" class="padding_section">
    <div id="aside_actualites_header" class="content_width">
      <h2 id="aside_actualites_title">
        {{ options.titre_de_la_sidebar_actualites }}
      </h2>

      <div id="aside_actualites_wrapper">
        <article
          v-for="(post, index) in posts" id="post-15300" :key="post.id"
          class="article_extrait post-15300 post type-post status-publish format-standard hentry category-uncategorized-fr article_extrait_1"
        >
          <div v-if="index <= 2">
            <div class="article_extrait_wrapper_text">
              <p class="entry_title_date legende">
                <span class="date_jour">
                  {{ post.date.day }}
                </span>
                <span class="date_mois">
                  {{ post.date.month }}
                </span>
                <span class="date_annee">
                  {{ post.date.year }}
                </span>
              </p>
              <h2 class="article_extrait_post_title">
                <router-link
                  :to="{
                    name: 'Principale',
                    params: { lang, page: 'news', subpage: post.slug },
                  }"
                >
                  {{
                    post.title.rendered
                  }}
                </router-link>
              </h2>
              <p>{{ post.acf.description_extrait_de_la_page }}</p>
            </div>

            <router-link
              class="btn btn_article_extrait"
              aria-hidden="true"
              :to="{
                name: 'Principale',
                params: { lang, page: 'news', subpage: post.slug },
              }"
            >
              {{ options['call-to-action_des_extraits_darticles'] }}
            </router-link>
          </div>
        </article>
      </div>

      <div class="remove_margin" />

      <p class="cta_btn_lead see-all-news">
        <router-link
          v-if="lang"
          aria-hidden="true"
          :to="{
            name: 'Principale',
            params: { lang, page: 'news' },
          }"
        >
          {{ options.texte_du_bouton_pour_voir_tous_les_articles }}
        </router-link>
      </p>
    </div>
  </aside>
</template>

<style>

</style>
