<!--
 1/ La route charge le contenu de la page, ce qui modifie le slug de la page dans le store
 2/ Le slug est "watched" : s'il est modifié, alors on affiche ou pas certains composants
 3/ Le contenu de la page est chargé dans le store grâce à la route
 4/ Les manipulations de la DOM après le rendu sont réalisés comme suit :
      a - Une référence est $emit depuis le composant enfant jusqu'à celui-ci, et la référence est stockée en data
      b - quand ce composant est updated, on vérifie sur quelle page on se trouve, et s'il faut manipuler la dom
-->

<script>
import he from 'he'
import { useHead } from '@unhead/vue'
import { mapActions, mapState } from 'pinia'
import { videosIframe } from '@/utils/videoIframe'
import { useMultilingualStore } from '@/store/MultilingualStore'
import { resizeColonneDoubleLarge } from '@/utils/resizeColonneDoubleLarge'
import { ajoutDeClasses } from '@/utils/ajoutDeClasses'
import { changeEntryContentCss } from '@/utils/changeEntryContent'
import { blocQuestionReponse } from '@/utils/blocQuestionReponse'
import PreFooter from '@/components/template-part/PreFooter.vue'
import HeaderPart from '@/components/template-part/HeaderPart.vue'
import HeaderHome from '@/components/template-part/HeaderHome.vue'
import AsideActualites from '@/components/template-part/AsideActualites.vue'
import EsgTalk from '@/components/template-part/EsgTalk.vue'
import ContactForm from '@/components/template-part/ContactForm.vue'
import BandeauHeader from '@/components/template-part/BandeauHeader.vue'
import NewsPage from '@/components/template-part/NewsPage.vue'
import NavBlog from '@/components/template-part/NavBlog.vue'
import FooterPart from '@/components/template-part/FooterPart.vue'
import CoursesExcerpt from '@/components/template-part/CoursesExcerpt.vue'
import SearchBox from '@/components/template-part/SearchBox.vue'
import SearchResults from '@/components/template-part/SearchResults.vue'
import CookiesBanner from '@/components/template-part/CookiesBanner.vue'
import NotFound from '@/components/template-part/NotFound.vue'

export default {
  name: 'RenderPage',
  components: { NotFound, CookiesBanner, SearchResults, SearchBox, PreFooter, FooterPart, HeaderPart, HeaderHome, AsideActualites, EsgTalk, ContactForm, BandeauHeader, NewsPage, NavBlog, CoursesExcerpt },
  data: () => ({
    app: HTMLElement,
    showHomeHeader: false,
    showESGTalksList: false,
    showContactForm: false,
    showAsideActualites: true,
    showBandeauHeader: false,
    showNews: false,
    showNavBlog: false,
    showCourses: false,
    show404: false,
    showSearchBox: false,
    showSearchResults: false,
    handleResponsiveMenu: {
      optionsLoaded: false,
      menusLoaded: false,
      dimensionsLogoNormal: '',
      dimensionsLogoSroll: '',
    },
    refESGTalks: HTMLElement,
    refCourseList: HTMLElement,
  }),
  computed: {
    ...mapState(useMultilingualStore, ['searchBoxOpened', 'menu', 'options', 'optionsPage', 'seo', 'id', 'protocol', 'host', 'website', 'page', 'uri', 'lang', 'options', 'pageSlug', 'subpageSlug', 'esgTalks','newsSlugs','contactSlugs']),
    isPageLoaded() {
      return this.page.length > 0 || 
             Object.values(this.newsSlugs).includes(this.pageSlug) || 
             Object.values(this.contactSlugs).includes(this.pageSlug) || 
             ['search', ...Object.values(this.newsSlugs), ...Object.values(this.contactSlugs)].includes(this.$route.params.page)
    }
  },
  watch: {
    options: {
      handler(val) {
        if (val) {
          useHead({
            script: [
              {
                name: 'piwik',
                innerHTML: val.tracking_matomo,
                type: 'text/javascript',
              },
            ],
          })
        }
      },
    },
    optionsPage: {
      handler(val) {
        if (val) {
          useHead({
            meta: [
              {
                name: 'description',
                content: val.description_extrait_de_la_page,
              },

            ],
            script: [
              {
                'src': 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
                'data-settings-id': '8gY6QA6UUoNEZx',
                'id': 'usercentrics-cmp',
                'async': true,
              },
            ],
          })
        }
      },
    },
    seo: {
      handler(val) {
      //  console.log(val)
        if (val) {
          useHead({
            title: val.title,
            meta: [
              {
                property: 'og:title',
                content: val.title,
              },
            ],
          })
        }
      },
    },
    uri: {
      handler(val) {
        //console.log('URI changed:', val);
        // Assurons-nous que l'URI n'a pas de barre oblique finale avant de l'utiliser
        this.urlToUri(val.replace(/\/$/, ''));
      },
    },
    pageSlug: {
      handler(value) {
        // console.log('Le pageSlug est : ', value)
        this.handleSlugAndComponents(value)
      },
    },
    subpageSlug: {
      handler(val) {
        this.handleSlugAndComponents(this.pageSlug)
      },
    },
  },

  mounted() {
    this.app = document.querySelector('#app')
  },
  /**
   * Ici sont gérées la plupart des fonctions du Nanosite. Ce sont des fonctiosn qui se trouvaient dans le main.js du site WordPress et qui agissent sur la DOM.
   */
  updated() {
    // console.log('render est updated')
    /**
     * Gestions des sous-pages contenant un bloc montrant des Custom Post Types. Ces contenus ne sont pas chargés par défaut par l'API Wordpress, il faut donc les rajouter manuellement dans la page a posteriori.
     */
    if (['', undefined].includes(this.$route.params.subpage)) { // On vérifie si on se trouve dans une sous-page
      switch (this.pageSlug) {
        case 'esg-talks': {
          const resultsESGTalks = document.querySelector('#result')
          if (resultsESGTalks) {
            resultsESGTalks.append(this.refESGTalks)
          }
          break
        }
        case 'courses': {
          const resultsCourses = document.querySelector('#result')
          if (resultsCourses) {
            const liste_resultats = resultsCourses.querySelector('#liste_resultats')
            if (liste_resultats) {
              liste_resultats.remove()
            }
            resultsCourses.append(this.refCourseList)
          }

          break
        }
        default:
          break
      }
    }

    this.changeHyperlink()

    const afficherLaVideo = document.querySelectorAll('.afficher_la_video')
    if (afficherLaVideo) {
      videosIframe(afficherLaVideo)
    }
    resizeColonneDoubleLarge()
    ajoutDeClasses()
    changeEntryContentCss()
    blocQuestionReponse()
    // consoCarbone()
    this.decodeHtmlInTree(this.app)
  },

  methods: {
    ...mapActions(useMultilingualStore, ['updateTitle']),
    /**
     * Gère l'affichage des composants en fonction du slug de la page et de la route de la sous-page (page et sous-page)
     */
    decodeHtmlInTree(node) {
      const nodes = node.childNodes
      for (let i = 0; i < nodes.length; i++) {
        const currentNode = nodes[i]
        if (currentNode.nodeType === Node.TEXT_NODE) {
          currentNode.textContent = currentNode.textContent
            ? he.decode(currentNode.textContent)
            : ''
        }
        else if (currentNode.nodeType === Node.ELEMENT_NODE) {
          this.decodeHtmlInTree(currentNode)
        }
      }
    },
    handleSlugAndComponents(slug) {
      // console.log('Gestion du slug et des composants, slug : ', slug)

      // Default states
      const defaultState = {
        showHomeHeader: false,
        showContactForm: false,
        showESGTalksList: false,
        showAsideActualites: true,
        showBandeauHeader: true,
        showCourses: false,
        show404: false,
        showNews: false,
        showSearchBox: false,
        showSearchResults: false,
        showNavBlog: false,
      }

      // States per slug
      const states = {
        '404': { show404: true },
        'search': {
          showNews: false,
          showHomeHeader: false,
          showBandeauHeader: true,
          showCourses: false,
          showSearchBox: false,
          showSearchResults: true,
          show404: false,
        },
        'home': {
          showNews: false,
          showHomeHeader: true,
          showBandeauHeader: false,
          showCourses: false,
        },
        '': {
          showNews: false,
          showHomeHeader: true,
          showBandeauHeader: false,
          showCourses: false,
        },
        'news': {
          showNews: !this.$route.params.subpage,
          showNavBlog: !!this.$route.params.subpage,
          showAsideActualites: !!this.$route.params.subpage,
          showCourses: false,
        },
        'esg-talks': {
          showNews: false,
          showESGTalksList: !this.$route.params.subpage,
          showNavBlog: !!this.$route.params.subpage,
          showCourses: false,
        },
        'contact': {
          showNews: false,
          showContactForm: true,
          showCourses: false,
        },
        'courses': {
          showNews: false,
          showContactForm: false,
          showCourses: !this.$route.params.subpage,
          showNavBlog: !!this.$route.params.subpage,
        },
      }

      // Assign the state
      const newsSlug = this.newsSlugs[this.lang]
      const contactSlug = this.contactSlugs[this.lang]
      const state = Object.assign({}, 
        defaultState, 
        states[slug] || 
        (Object.values(this.newsSlugs).includes(slug) ? states['news'] : {}) ||
        (Object.values(this.contactSlugs).includes(slug) ? states['contact'] : {})
      )

      for (const [key, value] of Object.entries(state)) {
        this[key] = value
      }
    },

    /**
     * Modifie l'URL avec l'URI (dans Wordpress, URL = slug, et URI = permalien via l'extension "Permalink manager")
     * Cette méthode permet de ne pas trigger le watcher et de relancer le processus d'appel de page, etc.
     */
    // urlToUri(uri) {
    //   if (uri !== 'home') {
    //     const history = window.history
    //     history.replaceState({}, null, `/${this.lang}/`) // on supprime la précédente URL tout en conservant la langue
    //     history.pushState( // on pousse la nouvelle URI
    //       {},
    //       null,
    //       uri,
    //     )
    //   }
    // },
    urlToUri(uri) {
      if (uri !== 'home') {
        const currentPath = window.location.pathname;
        // Assurons-nous que l'URI n'a pas de barre oblique finale
        const cleanUri = uri.replace(/\/$/, '');
        const desiredPath = `/${this.lang}/${cleanUri}`;
        
        // Vérifions si le chemin actuel est déjà correct
        if (currentPath !== desiredPath) {
          const history = window.history;
          history.replaceState({}, null, desiredPath);
        }
      }
    },

    /**
     * Modifie les hyperliens présents sur la page pour supprimer le protocole et le nom du site qui correspondent à l'API, pour les remplacer par ceux de l'APP.
     */
    changeHyperlink() {
      const links = document.querySelectorAll('a')
      links.forEach((link) => {
        if (link.host === this.website) {
          if (!link.pathname.startsWith('/wp-content/uploads')) {
            link.host = window.location.host
          }
        }
        if (link.protocol !== window.location.protocol) {
          link.protocol = window.location.protocol
        }
      })
    },
  },
}
</script>

<template>
  <HeaderPart />
  <div v-if="searchBoxOpened" id="menu_mask" style="top: 132px; display: block;" />
  <div id="global_content">
    <HeaderHome v-if="showHomeHeader" />
    <SearchBox v-if="searchBoxOpened" />
    <BandeauHeader v-if="showBandeauHeader" :route="$route" />

    <!-- Composant montrant les résultats de la recherche. Le tableau est rempli dans le store à la fonction search -->
    <SearchResults v-if="showSearchResults" />

    <!-- C'est ici que le contenu de la page est chargé grâce à l'object value.content.rendered. Dans le store,
      on recherche le contenu avec la route de la page, qui correspond au slug, puis on convertit la route en ID
      et on fait une recherche par ID si la recherche par slug ne donne rien.
    Quelques exceptions existent où il est prévu que value.content.rendered soit vide, elles sont listées ci-dessous
    -->

    <div v-if="isPageLoaded" v-html="page" />
    <div v-else-if="!show404" class="loader-container">
      <div class="lds-ripple">
        <div class="1" /><div class="2" />
      </div>
    </div>

    <EsgTalk v-if="showESGTalksList" @refESGTalks="(value) => { refESGTalks = value }" />
    <CoursesExcerpt v-if="showCourses" @refCourseList="(value) => { refCourseList = value }" />
    <ContactForm v-if="showContactForm || Object.values(contactSlugs).includes(pageSlug)" />
    <NavBlog v-if="showNavBlog" />
    <NotFound v-if="show404" />
    <AsideActualites v-if="showAsideActualites" />
    <NewsPage v-if="showNews" />
    <PreFooter />
    <FooterPart />
    <CookiesBanner />
  </div>
  <router-view />
</template>

<style>
.loader-container {
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid black;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
