<script>
export default {
  name: 'SearchIcon',

}
</script>

<template>
  <svg
    id="search_icon"
    ref="searchIcon"
    class="search_icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
  >
    <circle class="search_icon_cls" cx="33.97" cy="34.97" r="28.47" />
    <line class="search_icon_cls" x1="74" y1="75" x2="53.9" y2="54.9" />
  </svg>
</template>
