<script>
import { mapState, mapActions } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
import SocialNetworks from './SocialNetworks.vue'

export default {
  name: 'PreFooter',
  components: { SocialNetworks },
  
  data() {
    return {
      permalinksReady: false
    }
  },
  
  computed: {
    ...mapState(useMultilingualStore, ['options', 'menuFooter', 'lang', 'translatedPermalinks']),
    menuItems() {
      return this.menuFooter.menu.map(item => {
        const translatedPermalink = this.getTranslatedPermalink(item.slug)
        //console.log(`Footer menu item - Original slug: ${item.slug}, Translated permalink: ${translatedPermalink}`)
        return {
          ...item,
          translatedPermalink
        }
      })
    }
  },
  
  methods: {
    ...mapActions(useMultilingualStore, ['getTranslatedPermalink', 'getTranslatedPermalinks']),
    async loadPermalinks() {
      await this.getTranslatedPermalinks()
      //console.log('Permalinks loaded for footer menu:', this.translatedPermalinks)
      this.permalinksReady = true
    }
  },
  
  created() {
    this.loadPermalinks()
  }
}
</script>

<template>
  <aside id="aside_prefooter" class="padding_section">
    <div id="aside_prefooter_wrapper" class="content_width">
      <div id="aside_prefooter_col_logo" class="aside_prefooter_col">
        <div>
          <figure id="logo_footer" class="width_logo_70">
            <img :src="options.importer_le_logo_footer" alt="Logo Candriam">
          </figure>
          <p id="aside_prefooter_baseline">
            {{ options.accroche_sous_le_logo_footer }}
          </p>
        </div>
      </div>
      <div class="pre-footer-right">
        <span id="menu-footer" v-if="permalinksReady">
          <router-link
            v-for="item in menuItems" 
            :key="item.slug" 
            :to="item.translatedPermalink"
          >
            {{ item.title }}
          </router-link>
        </span>
        <SocialNetworks />
      </div>
    </div>
    <div class="remove_margin" />
  </aside>
</template>

<style>
@media (max-width: 1024px) {
#aside_prefooter_col_logo {
  width: 100%;
}
#aside_prefooter_col_logo #logo_footer {
  width: 50%;
}
#aside_prefooter_wrapper #aside_prefooter_baseline {
  white-space: wrap !important;
}
#aside_prefooter_col_logo {
  margin-left: 0 !important;
}
.pre-footer-right #menu-footer {
  display: flex;
  flex-direction: column;
}
.pre-footer-right #menu-footer a {
  white-space: wrap;
}
}
</style>
