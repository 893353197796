<script>
import { mapState, mapActions } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'

export default {
  name: 'MainMenu',
  data() {
    return {
      permalinksReady: false
    }
  },
  computed: {
    ...mapState(useMultilingualStore, ['menu', 'lang', 'frontendUrl', 'translatedPermalinks']),
    menuItems() {
      return this.menu.menu.map(item => {
        const translatedPermalink = this.getTranslatedPermalink(item.slug)
        //console.log(`Original slug: ${item.slug}, Translated permalink: ${translatedPermalink}`)
        // Supprimons la barre oblique finale si elle existe
        return {
          ...item,
          translatedPermalink: translatedPermalink.replace(/\/$/, '')
        }
      })
    }
  },
  methods: {
    ...mapActions(useMultilingualStore, ['getTranslatedPermalink', 'getTranslatedPermalinks']),
    async loadPermalinks() {
      await this.getTranslatedPermalinks()
      //console.log('Permalinks loaded:', this.translatedPermalinks)
      this.permalinksReady = true
    }
  },
  created() {
    this.loadPermalinks()
  }
}
</script>

<template>
  <nav v-if="permalinksReady" id="menu_header" ref="mainMenu" :class="`content_hide_menu content_hide_menu_search menu-${lang}`">
    <div id="menu_header_wrapper">
      <ul class="menu">
        <li v-for="item in menuItems" :key="item.slug" class="menu-item menu-item-type-post_type">
          <router-link :to="item.translatedPermalink">
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style>
@media (max-width: 1300px) {
  .menu-ja {
    display: none;
  }
}
@media (max-width: 1270px) {
  .menu-fr, .menu-de, .menu-es, .menu-nl {
    display: none;
  }
}

@media (max-width: 1200px) {
  .menu-it, .menu-en {
    display: none;
  }
}
</style>
