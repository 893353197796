export function videosIframe(afficherLaVideo) {
  // on récupère toutes les vidéos de la page

  // itérations sur les vidéos de la page
  afficherLaVideo.forEach((videoDiv) => {
    videoDiv.addEventListener('click', () => {
      const videoType = videoDiv.getAttribute('videoType')
      const urlVideo = videoDiv.getAttribute('urlVideo')
      switch (videoType) {
        case 'vimeo':
          const regExpVimeo = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(urlVideo)[3]
          const matchVimeo = urlVideo.match(regExpVimeo)
          if (matchVimeo) {
            const iframe = document.createElement('iframe')
            iframe.style.width = '640'
            iframe.style.height = '360'
            iframe.allowFullscreen = true
            iframe.src
                = `https://player.vimeo.com/video/${
                 matchVimeo
                 }?autoplay=1&color=ffffff&title=0&byline=0&portrait=0`
            videoDiv.parentNode.appendChild(iframe)
            videoDiv.style.display = 'none'
          }
          break
        case 'youtube':
          const siteUrl = videoDiv.getAttribute('siteUrl') // l'url du site est nécessaire pour les iframes youtube
          const regExpYoutube = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
          const matchYoutube = urlVideo.match(regExpYoutube)
          if (matchYoutube && matchYoutube[7].length == 11) {
            const b = matchYoutube[7]
            const iframe = document.createElement('iframe')
            iframe.style.width = '425'
            iframe.style.height = '349'
            iframe.allowFullscreen = true
            iframe.src
                = `https://www.youtube.com/embed/${
                 b
                 }?autoplay=1&origin=${
                 siteUrl}`
            videoDiv.parentNode.appendChild(iframe)
            videoDiv.style.display = 'none'
          }
          else {
            // console.log('L\'url de la vidéo est incorrecte.')
          }
          break
        default:
          break
      }
    })
  })
}
