<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'

export default {
  name: 'EsgTalk',
  emits: ['refESGTalks'],
  computed: {
    ...mapState(useMultilingualStore, ['esgTalks', 'options', 'lang']),
  },
  mounted() {
    this.$emit('refESGTalks', this.$refs.esgTalkList)
  },
  updated() {
    // console.log('Composant ESG Talk est updated')
  },

}
</script>

<template>
  <div id="esgTalkList" ref="esgTalkList">
    <!-- ref permet de garder une référence du contenu de la DOM pour pouvoir le manipuler ensuite -->
    <div v-for="esgTalk in esgTalks" :key="esgTalk.numero" class="term_extrait esg-talks">
      <div class="term_extrait_content">
        <div class="entry-content">
          <div class="date-esg-talk">
            <p class="entry_title_date legende">
              <span class="date_jour">{{ esgTalk.date.day }}</span><span class="date_mois">{{ esgTalk.date.month
              }}</span><span class="date_annee">{{ esgTalk.date.year }}</span>
            </p>
          </div>
          <div class="numero-esj-talk">
            #{{ esgTalk.numero }}
          </div>
          <h3>
            <router-link
              :to="{
                name: 'Principale',
                params: { lang, page: 'esg-talks', subpage: esgTalk.slug },
              }"
            >
              {{
                esgTalk.title.rendered
              }}
            </router-link>
          </h3>
          <p class="read-more">
            <router-link
              :to="{
                name: 'Principale',
                params: { lang, page: 'esg-talks', subpage: esgTalk.slug },
              }"
            >
              {{ options['call-to-action_sur_les_extraits_des_offres'] }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.esg-talks h3 a {
  padding: 0 !important;
}
#esgTalkList {
  display: grid;
    grid-template-columns: repeat(auto-fill, 30%);
    grid-gap: 1rem;
    justify-content: space-between;
    width: 80%;
    margin: auto;
}
@media (max-width: 1400px) {
  #esgTalkList {
    width: 90%;
  }
}
@media (max-width: 1100px) {
  #esgTalkList {
    grid-template-columns: repeat(auto-fill, 45%);
    width: 90%;
  }
}

@media (max-width: 700px) {
  #esgTalkList {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}
</style>
