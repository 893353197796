<script>
import { mapState } from 'pinia'
import he from 'he'
import { useMultilingualStore } from '../../store/MultilingualStore'

export default {
  name: 'CoursesExcerpt',
  computed: {
    ...mapState(useMultilingualStore, ['courses', 'options', 'lang']),
  },
  mounted() {
    // console.log('CourseExcerpt est mounted')
    this.$emit('refCourseList', this.$refs.courseList)
  },
  updated() {
    this.decodeHtmlInTree(this.$refs.courseList)
  },
  methods: {
    decodeHtmlInTree(node) {
      const nodes = node.childNodes
      for (let i = 0; i < nodes.length; i++) {
        const currentNode = nodes[i]
        if (currentNode.nodeType === Node.TEXT_NODE) {
          currentNode.textContent = currentNode.textContent
            ? he.decode(currentNode.textContent)
            : ''
        }
        else if (currentNode.nodeType === Node.ELEMENT_NODE) {
          this.decodeHtmlInTree(currentNode)
        }
      }
    },
  },
}
</script>

<template>
  <div id="liste_resultats" ref="courseList" class="content_width three-items courses">
    <!-- ref permet de garder une référence du contenu de la DOM pour pouvoir le manipuler ensuite -->
    <div v-for="course in courses" :key="course.id" class="term_extrait">
      <div class="term_extrait_content">
        <div class="entry-content">
          <h3>
            <router-link
              :to="{
                name: 'Principale',
                params: { lang, page: 'courses', subpage: course.slug },
              }"
            >
              {{
                course.title.rendered
              }}
            </router-link>
          </h3>
          <p>
            {{ course.description }}
          </p>
          <p class="read-more read-more-courses">
            <router-link
              :to="{
                name: 'Principale',
                params: { lang, page: 'courses', subpage: course.slug },
              }"
            >
              {{ options['call-to-action_des_extraits_darticles'] }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.courses .entry-content {
  padding-bottom: 1rem !important;
}

@media (max-width: 768px) {
  .courses-list .term_extrait {
    width: 100% !important;
  }
}
</style>
