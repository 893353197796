<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
import BoxarrowRight from '../svg/BoxarrowRight.vue'

export default {
  name: 'ContactButton',
  components: { BoxarrowRight },
  emits: ['contactButtonLoaded'],
  data() {
    return {
      showText: true,
      clientWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapState(useMultilingualStore, ['options']),
  },
  watch: {
    clientWidth(val) {
      return this.showText = val > 700
    },
  },
  updated() {
    this.$emit('contactButtonLoaded', this.$refs)
  },
  mounted() {
    this.checkWindowSizeOnMounted()
    window.addEventListener('resize', () => {
      this.clientWidth = window.innerWidth
    })
  },
  methods: {
    checkWindowSizeOnMounted() {
      this.showText = window.innerWidth > 700
    },
  },
}
</script>

<template>
  <div id="header_contact" ref="contactButton" class="content_hide_menu content_hide_menu_search">
    <div class="cta_btn_lead">
      <a :class="[showText ? '' : 'round']" :href="options.url_du_bouton_contact">
        <span class="box-arrow-right-svg">
          <BoxarrowRight />
        </span>
        <span v-if="showText"> {{ options.texte_du_bouton_contact }}</span>
      </a>
    </div>
  </div>
</template>

<style scoped>
.round {
  padding: 1rem 1.2rem 1rem 1rem !important;
}
</style>
