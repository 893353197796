export function changeEntryContentCss() {
  const elementsEntryContent = document.querySelectorAll(
    '.page_defaut .entry-content, .onepage .entry-content, .article_blog .entry-content',
  )
  if (elementsEntryContent.length > 0) {
    elementsEntryContent.forEach((element) => {
      if (element.firstElementChild) {
        element.firstElementChild.style.paddingTop = '0'
        element.firstElementChild.style.marginTop = '0'
      }
      if (element.lastElementChild) {
        element.lastElementChild.style.paddingBottom = '0'
        element.lastElementChild.style.marginBottom = '0'
      }
    })
  }

  const listedesOffres = document.querySelectorAll(
    '.page_defaut .liste_des_offres .content_width',
  )
  if (listedesOffres) {
    listedesOffres.forEach((liste) => {
      liste.firstElementChild.style.marginTop = '0px'
    })
  }
}
